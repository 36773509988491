<template>
    <div class="downloads">
        <h1>{{ msg }}</h1>
        
        <ul>
            <li><b-form-input v-model="serial" placeholder="Enter serial number"></b-form-input></li>
            <li><b-button id="fetchPackageBtn" variant="primary" v-on:click="fetchPackages" :key="rerenderKey">{{fetchMessage}}</b-button></li>
        </ul>
        <PackageList v-bind:packages="packages" v-bind:serial="serial"/>
        <b-modal id="fetching_modal" title="Downloader">
            Fetching package list... 
            <b-spinner/>
        </b-modal>
    </div>
</template>

<script>
import PackageList from './PackageList.vue'
import {BFormInput, BButton, BModal} from 'bootstrap-vue'

export default {
    name: 'DownloadablePackages',
    components:{
        PackageList,
        'b-form-input': BFormInput,
        'b-button': BButton,
        'b-modal': BModal
    },
    data: function() {
        return {
            serial: '', 
            packages: [],
            noData: false,
            fetchMessage: "Fetch packages",
            rerenderKey : 0
        }
    },
    props: {
        msg: String, 
    }, 
    methods: {
        fetchPackages() {
            //Fetch packages for a serialnumber from AWS
            this.$bvModal.show("fetching_modal");
            
            let header = new Headers();
            header.set("Content-Type", "application/json");

            header.set("X-Api-Key", "d6Sb2UGNam2ObWjRjH1JT43VcssL0pxeaz2UjMx2");
                
            let jsonBody = {
                "Serialnumber": this.serial,
                "Password": ""
            };

            header.set("Accept", "*/*");

            const myPost = {
                method: "POST",
                headers: header,
                mode: 'cors',
                body: JSON.stringify(jsonBody)
            };
                
            fetch("https://9avbl7vhui.execute-api.eu-north-1.amazonaws.com/test",
                myPost)
            .then(res => res.json())
            .then(json => {
                this.$bvModal.hide("fetching_modal");
                this.packages = [];
                json.forEach(element => {
                    this.packages.push({
                        name: element
                    });
                })

                if(this.packages.length == 0)
                {
                    this.noData = true;
                    alert("No packages was found for this serial number");
                }
                else {
                    this.noData = false;
                }
                    
            }).catch(() => {
                this.packages = [];
                this.noData = true;
            });
        }
    }
}
</script>

<style scoped>
h1 {
  padding-bottom: 10px;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
#fetchPackageBtn {
    margin-bottom: 2px;
}

</style>
<template>
  <div id="app">
    <img id="logo" alt="PKI logo" src="./assets/logo.png">
    <DownloadablePackages msg="Remote Delivery PoC"/>
  </div>
</template>

<script>
import DownloadablePackages from './components/Packages.vue'

export default {
  name: 'app',
  components: {
    DownloadablePackages
  }
}
</script>

<style>
#app {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
#logo {
  padding-bottom: 20px;
}
</style>

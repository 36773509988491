<template>
   <div class="packagelist">
       <b-table-simple striped hover v-if="packages.length > 0" class="spinning">
           <b-thead>
               <b-th>Package</b-th>
               <b-th>Download</b-th>
           </b-thead>
           <b-tbody>
               <b-tr v-for="item in packages" v-bind:key="item.name">
                   <b-td>{{item.name}}</b-td>
                   <b-td>
                       <b-button @click="downloadPackage(item.name)" v-bind:disabled="!canDownload" variant="primary">
                           Download
                        </b-button>
                   </b-td>
               </b-tr>
           </b-tbody>
        </b-table-simple>
        <b-modal id="my_modal" v-bind:title="modalTitle">
            {{modalMessage}}
        </b-modal>
        <b-modal id="download_modal" title="Downloader">
            Downloading please wait...
            <b-spinner/>
        </b-modal>
   </div>
</template>

<script>
import {BButton, BTableSimple, BModal, BSpinner} from 'bootstrap-vue'

export default {
    name: 'PackageList',
    components: {
        'b-button': BButton,
        'b-table-simple': BTableSimple,
        'b-modal': BModal,
        'b-spinner': BSpinner
    },
    data: function(){
        return {
            modalTitle: "",
            modalMessage: "",
            canDownload: true
        }
    },
    props: {
        packages: Array,
        serial: String
    },
    methods: {
        downloadPackage(itemName){
            if(!this.canDownload)
                return;

            let itemToDownload = itemName;
            if(!itemToDownload.includes(".zip"))
            {
                itemToDownload = itemToDownload.concat(".zip");
            }

            var jsonBody = JSON.stringify({
                Package: itemToDownload,
                SerialNumber: this.$props.serial
            });

            let header = new Headers();
            header.append("Content-Type", "application/json");
            const myPost = {
                method: "POST",
                headers: header,
                body: jsonBody,
                mode: 'cors'
            };

            this.canDownload = false;
            this.$bvModal.show("download_modal");

            fetch("https://cryptographyapi.perten.perkinelmer.com/api/cryptography", myPost)
            .then(Response => {
                if(Response.ok)
                {
                    return Response.blob();
                }
            })
            .then(blob => {
                this.$bvModal.hide("download_modal");
                //window.console.log(blob);
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.style.display = 'none';
                a.href = url;

                a.download = itemToDownload;
                document.body.appendChild(a);
                a.click();
                window.URL.revokeObjectURL(url);
            })
            .catch(() => {
                this.$bvModal.hide("download_modal");
                this.modalMessage = "Unable to download package";
                this.modalTitle = "Error while encrypting and downloading package";
                this.$bvModal.show("my_modal");
            });
            this.canDownload = true;
        }
    }
}
</script>

<style scoped>
table{
    list-style-type: none;
    padding: 0;
    margin-right: auto;
    margin-left: auto;
    background-color: white;
    border: 1px grey;
    width: auto;
}
th, td {
  min-width: 120px;
  padding: 10px 20px;
  text-align: left;
}
td {
  background-color: #f9f9f9;
}
th {
  background-color: #42b983;
  color: rgba(255,255,255,0.66);
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}


</style>